const initialState = {
  showAlert: false,
  alertTitle: "",
  alertMessage: "",
  alertType: "",
  ipCountryCode: "za"
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_ALERT_THROWN':
      return { ...state, 
        showAlert: true,
        alertTitle: action.value.title,
        alertMessage: action.value.message,
        alertType: action.value.type
      }
    case 'HANDLE_DISMISS_ALERT':
      return { ...state, 
        showAlert: false,
        alertTitle: "",
        alertMessage: "",
        alertType: ""
      }
    case 'SET_IP_COUNTRY_CODE':
      return { ...state, 
        ipCountryCode: action.value.toLowerCase()
      }
    default:
      return state
  }
}

export default appReducer
