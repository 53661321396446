// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import registration from './registration'
import app from './app'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  registration,
  app
})

export default rootReducer
