const initialState = {
    type: "Operator",
    subtype: "DMC",
    name: "",
    surname:  "",
    email: "",
    password: "",
    companyName: "",
    businessLocation: "",
    phoneNumber: "",
    termsAndConditions: false
}

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_TYPE_SELECTION':
      return { ...state, 
        type: (action.value === "DMC" || action.value === "TourOperator" || action.value === "TravelAgent") ? "Operator" : "Supplier", 
        subtype: action.value
      }
    case 'HANDLE_PERSONAL_DETAILS_SUBMIT':
      return { ...state, 
        name: action.value.name,
        surname: action.value.surname,
        email: action.value.email,
        password: action.value.password
      }
    case 'HANDLE_COMPANY_DETAILS_SUBMIT':
      return { ...state, 
        companyName: action.value.companyName,
        businessLocation: action.value.country,
        phoneNumber: action.value.phoneNumber,
        termsAndConditions: action.value.acceptedTermsAndConditions
      }
      case 'HANDLE_EMAIL_UPDATE':
      return { ...state, 
        email:  action.value
      }
    default:
      return state
  }
}

export default registrationReducer
